import {BannerContainer, LookContainer, LookWrapper} from "./HomePage.style";
import {Container} from "../../../styles/GlobalStyle";
import React from "react";


const Look = () => {
  return (
    <LookWrapper className="main-look">
      <Container>
        <LookContainer className="main-look__wp">
            <div className="main-look__left">
              <h2 className="main-look__title" data-aos="fade-right" data-aos-duration="700">
                Вы - хороший
                <br/>
                наёмный менеджер.
              </h2>
            </div>

          <div className="main-look__right">
            <p data-aos="fade-left" className="main-look__right-first" data-aos-delay="100" data-aos-duration="700">
              Это значит, что вам нужно всё время оглядываться в поисках следующего хорошего места найма. Но вам некогда это делать, потому что вы полностью заняты на вашем текущем месте.
            </p>
            <p data-aos="fade-left" data-aos-delay="300" data-aos-duration="700">
              И поэтому вам нужен Facancy.
            </p>
          </div>
        </LookContainer>
      </Container>
    </LookWrapper>
  )
}

export default Look;

// fadeInLeft
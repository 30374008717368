import {
  VacanciesHeader, VacanciesSlider, VacanciesSliderButtons, VacanciesSliderWrapper
} from "./HomeVacancy.style";
import {Container} from "../../../styles/GlobalStyle";
import React from "react";
import SwiperCore, { Navigation } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import {VacancySlideItem} from "./VacancySlide";


const Vacancies = ({ vacancies, profile }) => {
  SwiperCore.use([Navigation]);

  return (
    <VacanciesSliderWrapper className="main-slider" data-aos="fade" data-aos-duration="700">
      <Container>
        <div className="main-slider__wp">
          <VacanciesHeader className="main-slider__top">
            <h4>Вакансии недели</h4>
            <a href={'/vacancies'} className="link-arrow">
              <span>
                <svg width="30" height="16">
                  <svg id="icon-arrow" viewBox="0 0 97 53">
                    <path d="M88.586 24.5h1.195l-.839-.851-11.565-11.732a2.026 2.026 0 0 1 0-2.834 1.94 1.94 0 0 1 2.773 0l.356-.35-.356.35 15.772 16c.77.782.77 2.052 0 2.834l.356.35-.356-.35-15.771 16a1.94 1.94 0 0 1-2.773 0 2.026 2.026 0 0 1 0-2.834l11.564-11.732.839-.851H2.464C1.386 28.5.5 27.611.5 26.5s.886-2 1.964-2h86.122z" stroke="#fff"></path>
                  </svg>
                </svg>
              </span>
              <div>Смотреть все вакансии</div>
            </a>
          </VacanciesHeader>

          <VacanciesSlider className="main-slider__slider">
            <Swiper
              spaceBetween={0}
              slidesPerView={1}
              navigation={{
                nextEl: ".main-slider__slider-next",
                prevEl: ".main-slider__slider-prev"
              }}
              loop={true}
            >
              {
                vacancies.map( (it, i) => {
                  return (
                    <SwiperSlide key={i}>
                      <VacancySlideItem vacancy={it} profile={profile}/>
                    </SwiperSlide>
                  )
                })
              }
            </Swiper>
          </VacanciesSlider>

          <VacanciesSliderButtons className="main-slider__slider-buttons">
            <div className="main-slider__slider-prev" tabIndex="0" role="button" aria-label="Previous slide">
              <svg width="60" height="60">
                <svg id="icon-arrow-swiper" viewBox="0 0 60 60">
                  <path d="M35.983 30.184L23.25 41.395a.812.812 0 1 1-1.072-1.223l11.437-9.984a1 1 0 0 0 0-1.507l-11.437-9.983a.812.812 0 1 1 1.072-1.222l12.733 11.208a1 1 0 0 1 0 1.502z"></path><path d="M23.25 41.393l.33.375-.33-.375zm12.733-11.209l.33.376-.33-.375zm0-1.5l.33-.376-.33.375zM23.25 17.473l.33-.375-.33.376zm-1.072 1.223l.33-.377-.33.377zm11.437 9.983l-.328.377.328-.377zm0 1.507l.33.377-.33-.377zm-11.437 9.984l.33.377-.33-.377zm-.028 1.198l.346-.36-.346.36zm0-23.87l-.346-.361.346.36zm1.43 24.27l12.734-11.21-.66-.75-12.735 11.209.66.75zm12.734-13.461L23.58 17.099l-.66.751 12.733 11.209.66-.751zm-14.465-9.235l11.438 9.984.657-.753-11.437-9.984-.658.753zm11.438 10.738l-11.438 9.983.658.754 11.437-9.984-.657-.753zm-11.438 9.983a1.312 1.312 0 0 0-.045 1.936l.692-.721a.313.313 0 0 1 .011-.461l-.658-.754zm11.438-10.737a.5.5 0 0 1 0 .754l.657.753a1.5 1.5 0 0 0 0-2.26l-.657.753zm-11.483-11.92a1.313 1.313 0 0 0 .046 1.936l.657-.753a.312.312 0 0 1-.01-.46l-.693-.722zm1.776-.038a1.313 1.313 0 0 0-1.776.039l.692.721a.313.313 0 0 1 .423-.009l.66-.75zm12.734 13.46a1.5 1.5 0 0 0 0-2.251l-.66.75a.5.5 0 0 1 0 .751l.66.75zM22.919 41.019a.313.313 0 0 1-.423-.01l-.692.722c.492.473 1.264.49 1.776.038l-.66-.75z"></path>
                </svg>
              </svg>
            </div>
            <div className="main-slider__slider-next" tabIndex="0" role="button" aria-label="Next slide">
              <svg width="60" height="60">
                <svg id="icon-arrow-swiper" viewBox="0 0 60 60">
                  <path d="M35.983 30.184L23.25 41.395a.812.812 0 1 1-1.072-1.223l11.437-9.984a1 1 0 0 0 0-1.507l-11.437-9.983a.812.812 0 1 1 1.072-1.222l12.733 11.208a1 1 0 0 1 0 1.502z"></path><path d="M23.25 41.393l.33.375-.33-.375zm12.733-11.209l.33.376-.33-.375zm0-1.5l.33-.376-.33.375zM23.25 17.473l.33-.375-.33.376zm-1.072 1.223l.33-.377-.33.377zm11.437 9.983l-.328.377.328-.377zm0 1.507l.33.377-.33-.377zm-11.437 9.984l.33.377-.33-.377zm-.028 1.198l.346-.36-.346.36zm0-23.87l-.346-.361.346.36zm1.43 24.27l12.734-11.21-.66-.75-12.735 11.209.66.75zm12.734-13.461L23.58 17.099l-.66.751 12.733 11.209.66-.751zm-14.465-9.235l11.438 9.984.657-.753-11.437-9.984-.658.753zm11.438 10.738l-11.438 9.983.658.754 11.437-9.984-.657-.753zm-11.438 9.983a1.312 1.312 0 0 0-.045 1.936l.692-.721a.313.313 0 0 1 .011-.461l-.658-.754zm11.438-10.737a.5.5 0 0 1 0 .754l.657.753a1.5 1.5 0 0 0 0-2.26l-.657.753zm-11.483-11.92a1.313 1.313 0 0 0 .046 1.936l.657-.753a.312.312 0 0 1-.01-.46l-.693-.722zm1.776-.038a1.313 1.313 0 0 0-1.776.039l.692.721a.313.313 0 0 1 .423-.009l.66-.75zm12.734 13.46a1.5 1.5 0 0 0 0-2.251l-.66.75a.5.5 0 0 1 0 .751l.66.75zM22.919 41.019a.313.313 0 0 1-.423-.01l-.692.722c.492.473 1.264.49 1.776.038l-.66-.75z"></path>
                </svg>
              </svg>
            </div>
          </VacanciesSliderButtons>
        </div>
      </Container>
    </VacanciesSliderWrapper>
  )
}

export default Vacancies;

import {DifferInfo, DifferInfoImage, DifferInfoItem, DifferInfoText, DifferWrapper} from "./HomePage.style";
import {Container} from "../../../styles/GlobalStyle";
import React from "react";
import {differInfos} from "../../../helpers/mock";


const Differ = () => {
  return (
    <DifferWrapper className="main-differ">
      <Container>
        <div className="main-differ__wp">
          <h2 data-aos="fade" data-aos-duration="700">
            Чем мы отличаемся
            <br/>
            <span className="orange-color">от обычных рассылок вакансий?</span>
          </h2>

          <DifferInfo className="main-differ__list">
            {
              differInfos?.map( (it, index) => {
                return (
                  <DifferInfoItem key={index} className="main-differ__item" data-aos="fade" data-aos-delay={`${ index + 1 }00`} data-aos-duration="700">
                    <DifferInfoImage className="main-differ__item-img">
                      <img src={`/images/home/differ-${index + 1}-icon.svg`} />
                    </DifferInfoImage>

                    <DifferInfoText className="main-differ__item-text" dangerouslySetInnerHTML={{ __html: it.text }} />
                  </DifferInfoItem>
                )
              })
            }
          </DifferInfo>
        </div>
      </Container>
    </DifferWrapper>
  )
}

export default Differ;
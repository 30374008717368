import React, {useCallback, useEffect, useState} from 'react';
import {Tooltip} from "antd";
import {VacancyTitleWrapper} from "../Page/Vacancy/Vacancies.style";
import {parseCookies} from "nookies";
import {CustomTooltip} from "./CustomTooltip";

const VacancyTitle = ({  vacancy }) => {

  const ABOUT_VACANCY_TOOLTIP_DATA = (
    <span  className="tooltip-text-wrapper">
      Повторная публикация
    </span>
  )

  return (
    <>
      {!vacancy.is_posted_again ? (
        vacancy.title
      ) : (
        <CustomTooltip placement="topLeft" overlayClassName="vacancy-posted-again-title"
                 title={ABOUT_VACANCY_TOOLTIP_DATA}>
          <VacancyTitleWrapper>{ vacancy.title }</VacancyTitleWrapper>
        </CustomTooltip>
      )}
      {/*{ vacancy.title }*/}
    </>
  )
}

export default VacancyTitle;
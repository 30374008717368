import {BannerContainer, BannerImage, BannerText, BannerWrapper} from "./HomePage.style";
import {ButtonOrange, Container} from "../../../styles/GlobalStyle";
import AnchorLink from "react-anchor-link-smooth-scroll";
import React from "react";


const Banner = ({ isSmall = false, bannerText = {} }) => {

  // const banner = {
  //   h1: isSmall ? 'Сервис для <br />' : 'Сервис для <br />',
  //   orange: isSmall ? 'хороших наёмных <br />менеджеров' : 'хороших наёмных <br />менеджеров',
  //   description: isSmall ? '<i>“Хороший наёмный менеджер начинает искать новую работу в первый день новой работы”</i> <br />… из Кодекса хорошего наёмного менеджера'
  //     : '<i>“Хороший наёмный менеджер начинает искать новую работу в первый день новой работы”</i> <br />…из Кодекса хорошего наёмного менеджера',
  //   button: isSmall ? 'Подписаться' : 'Подписаться',
  // }

  return (
    <BannerWrapper isSmall={isSmall} className="main-hero">
      <Container>
        <BannerContainer className="main-hero__wp" data-aos="fade" data-aos-duration="700">
          <BannerText className="main-hero__left">
            <h1 dangerouslySetInnerHTML={{ __html: bannerText.h1 + `<span className="orange-color">${ bannerText.orange }</span>` }} />
            <div dangerouslySetInnerHTML={{ __html: bannerText.description }} className="p1"></div>
            <div className="buttons-wrapper">
              <AnchorLink href='#subscription-form' style={{flex: 1}}>
                <button className="btn">
                  { bannerText.button }
                </button>
              </AnchorLink>

              <button className="btn2" style={{flex: 1}}>
                <a href="https://vac.facancy.ru" className={"vacancy-link"}>Разместить вакансию</a>
              </button>
            </div>

          </BannerText>

          <BannerImage className="main-hero__right" data-aos="fade" data-aos-duration="700">
            <img src="/images/home/hero.png" />
          </BannerImage>
        </BannerContainer>
      </Container>
    </BannerWrapper>
  )
}

export default Banner;
import {VacancyDateAndPlace, VacancyDescription, VacancyHeader, VacancySlide} from "./HomeVacancy.style";
import VacancyTitle from "../../Utils/VacancyTitle";
import VacancyPrice from "../../Utils/VacancyPrice";
import {clearHtmlAndSubstr} from "../../../helpers/utils";
import React from "react";


export const VacancySlideItem = ({ vacancy, profile }) => {
  return (
    <VacancySlide className="main-slider__slide">
      <VacancyHeader className="main-slider__slide-left">
        <div className="main-slider__slide-title">
          <a href={`/vacancies/${vacancy?.slug}`} target="_blank">
            <h2 style={{ margin: 0 }}>
              <VacancyTitle vacancy={vacancy}/>
              <VacancyPrice fullVacancy={vacancy} salary={vacancy?.salary} profile={profile}
                            bannerClassName={'slider-vacancies-banner'}
                            hidePriceClassName={'slider-vacancies'}/>
            </h2>
          </a>
        </div>
        <VacancyDateAndPlace className="main-slider__slide-date">
          <div className="podp">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M12.5 4.99967H15.1667C15.3435 4.99967 15.513 5.06991 15.6381 5.19494C15.7631 5.31996 15.8333 5.48953 15.8333 5.66634V15.9997C15.8333 16.1765 15.7631 16.3461 15.6381 16.4711C15.513 16.5961 15.3435 16.6663 15.1667 16.6663H3.16667C2.98986 16.6663 2.82029 16.5961 2.69526 16.4711C2.57024 16.3461 2.5 16.1765 2.5 15.9997V5.66634C2.5 5.48953 2.57024 5.31996 2.69526 5.19494C2.82029 5.06991 2.98986 4.99967 3.16667 4.99967H5.83333V3.33301H7.5V4.99967H10.8333V3.33301H12.5V4.99967ZM4.16667 8.33301V14.9997H14.1667V8.33301H4.16667ZM5.83333 10.833H9.16667V13.333H5.83333V10.833Z" fill="#EF6957"></path>
            </svg>
            { vacancy.date || '' }
          </div>
          <div className="podp">
            <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
              <path d="M15.1291 14.0685L14.1399 15.0468C13.4109 15.7624 12.4649 16.6823 11.3017 17.8069C10.5749 18.5097 9.42168 18.5096 8.69492 17.8067L5.78567 14.9768C5.42003 14.6178 5.11398 14.3151 4.86744 14.0685C2.03377 11.2349 2.03377 6.64057 4.86744 3.8069C7.70112 0.973222 12.2954 0.973222 15.1291 3.8069C17.9628 6.64057 17.9628 11.2349 15.1291 14.0685ZM12.0818 9.16664C12.0818 8.01595 11.149 7.08314 9.99827 7.08314C8.84758 7.08314 7.91477 8.01595 7.91477 9.16664C7.91477 10.3173 8.84758 11.2501 9.99827 11.2501C11.149 11.2501 12.0818 10.3173 12.0818 9.16664Z" fill="#EF6957"></path>
            </svg>
            { vacancy.city }
          </div>
        </VacancyDateAndPlace>
      </VacancyHeader>

      <VacancyDescription className="main-slider__slide-right">
        <div className="main-slider__slide-right__content"
             dangerouslySetInnerHTML={{ __html: clearHtmlAndSubstr(vacancy.text, true) }}></div>
        <div className="main-slider__slide-right__ellipsis">...</div>
      </VacancyDescription>

    </VacancySlide>
  )
}
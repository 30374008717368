import {
  HowWorkContainer, HowWorkText, HowWorkTextContainer,
  HowWorkTrack, HowWorkTrackBullets,
  HowWorkTrackItem,
  HowWorkTrackLeft,
  HowWorkTrackRight,
  HowWorkWrapper
} from "./HomePage.style";
import {Container} from "../../../styles/GlobalStyle";
import React from "react";


const HowWork = () => {

  const dots = Array(21).fill(null).map((_, i) => {
    return <span key={i}></span>
  })

  return (
    <HowWorkWrapper className="how-work">
      <Container className="container">
        <h2 data-aos="fade" data-aos-duration="700">Как работает наш сервис</h2>

        <div className="how-work__wp">
          <HowWorkTrack className="how-work__track" data-aos="fade" data-aos-duration="700">
            <HowWorkTrackLeft className="how-work__track-left">
              <HowWorkTrackItem className="how-work__track-item">
                <svg width="34" height="32" viewBox="0 0 34 32" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M29.4985 18.5586C30.1608 18.5586 30.6977 18.0218 30.6977 17.3595V10.4452C30.6977 7.80044 28.546 5.64878 25.9012 5.64878H20.7405V4.08542C20.7405 2.26832 19.2622 0.790039 17.4451 0.790039H13.2526C11.4355 0.790039 9.9572 2.26832 9.9572 4.08542V5.64872H4.79651C2.15172 5.64872 0 7.80044 0 10.4452V24.4151C0 27.0599 2.15172 29.2116 4.79651 29.2116H19.1497C19.812 29.2116 20.3488 28.6747 20.3488 28.0125C20.3488 27.3502 19.812 26.8133 19.1497 26.8133H4.79651C3.47411 26.8133 2.39826 25.7375 2.39826 24.4151V15.899H14.2097V17.7C14.2097 18.3622 14.7465 18.8991 15.4088 18.8991C16.0711 18.8991 16.6079 18.3622 16.6079 17.7V15.899H28.2994V17.3595C28.2994 18.0218 28.8363 18.5586 29.4985 18.5586ZM12.3555 4.08542C12.3555 3.59072 12.7579 3.18829 13.2526 3.18829H17.4451C17.9398 3.18829 18.3422 3.59072 18.3422 4.08542V5.64872H12.3555V4.08542ZM2.39826 13.5009V10.4453C2.39826 9.1229 3.47411 8.04704 4.79651 8.04704H25.9012C27.2236 8.04704 28.2994 9.1229 28.2994 10.4453V13.5009H2.39826Z" fill="#323232"></path>
                  <path fillRule="evenodd" clipRule="evenodd" d="M28.7778 24C28.7778 25.5341 27.5341 26.7778 26 26.7778C24.4659 26.7778 23.2222 25.5341 23.2222 24C23.2222 22.4659 24.4659 21.2222 26 21.2222C27.5341 21.2222 28.7778 22.4659 28.7778 24ZM28.7311 28.1889C27.946 28.7018 27.0078 29 26 29C23.2386 29 21 26.7614 21 24C21 21.2386 23.2386 19 26 19C28.7614 19 31 21.2386 31 24C31 25.0078 30.7018 25.946 30.1889 26.7311L33.5485 30.0906C33.951 30.4932 33.951 31.1459 33.5485 31.5485C33.1459 31.951 32.4932 31.951 32.0906 31.5485L28.7311 28.1889Z" fill="#EF6957"></path>
                </svg>
              </HowWorkTrackItem>

              <HowWorkTrackBullets className="how-work__track-bullets">
                { dots }
              </HowWorkTrackBullets>

              <HowWorkTrackItem className="how-work__track-item">
                <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8.5L2.66667 10L6 7" stroke="#EF6957" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <rect x="9" y="7" width="16" height="3" rx="1.5" fill="#EF6957"></rect>
                  <rect x="1" width="18" height="3" rx="1.5" fill="#323232"></rect>
                  <rect x="1" y="14" width="18" height="3" rx="1.5" fill="#323232"></rect>
                </svg>
              </HowWorkTrackItem>
            </HowWorkTrackLeft>

            <HowWorkTrackRight className="how-work__track-right">
              <HowWorkTrackItem className="how-work__track-item">
                <svg width="25" height="17" viewBox="0 0 25 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1 8.5L2.66667 10L6 7" stroke="#EF6957" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round"></path>
                  <rect x="9" y="7" width="16" height="3" rx="1.5" fill="#EF6957"></rect>
                  <rect x="1" width="18" height="3" rx="1.5" fill="#323232"></rect>
                  <rect x="1" y="14" width="18" height="3" rx="1.5" fill="#323232"></rect>
                </svg>
              </HowWorkTrackItem>
            </HowWorkTrackRight>
          </HowWorkTrack>

          <HowWorkText className="how-work__text">
            <HowWorkTextContainer className="how-work__text-item" data-aos="fade" data-aos-duration="700" data-aos-offset="-50">
              <div className="podz">Каждый день ищем вакансии по 182 источникам</div>
              <p>и собираем самые-самые из LinkedIn, Glassdoor, Телеграме, ВК, на корпоративных сайтах, сайтах рекрутинговых агентств и из многих других источников</p>
            </HowWorkTextContainer>

            <HowWorkTextContainer className="how-work__text-item" data-aos="fade" data-aos-delay="200" data-aos-duration="700" data-aos-offset="-50">
              <div className="podz">Еженедельно алгоритм отбирает для вас лучшие из них</div>
              <p>и присылает 10&nbsp;наиболее близких вашему опыту, показывая отдельно идеально вам подходящие, достаточно хорошие, и&nbsp;(если не&nbsp;набралось 10&nbsp;подходящих) самые близкие из&nbsp;тех, которые вам не&nbsp;очень подходят</p>
            </HowWorkTextContainer>
          </HowWorkText>
        </div>

      </Container>
    </HowWorkWrapper>
  )
}

export default HowWork;
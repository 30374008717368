export const account = {
  id: 1337,
  first_name: 'Иван',
  last_name: 'Иванов',
  patronymic_name: 'Иванович',
  balance: 12281,
  email: 'some@email.com',
  phone: '+7 (999) 888-77-66',
  is_subscribed: false,
  position: {
    company: '',
    company_position: '',
    industry: '',
    your_function: '',
  },
  prev_position: {
    company: '',
    company_position: '',
    industry: '',
    your_function: '',
  }
}

export const differInfos = [
  {
    text: '<b>Покажем</b> возможности, не ограничиваясь вашей отраслью и функцией',
  },
  {
    text: '<b>Найдем</b> вакансии, даже если нам их не пришлют работодатели',
  },
  {
    text: '<b>Ответим</b> на ваши вопросы о вакансиях',
  },
  {
    text: '<b>Не будем</b> публиковать вакансии токсичных работодателей и откровенный обман',
  },
]
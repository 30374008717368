import styled from "styled-components";
import {theme} from "/helpers/theme";
import {Container} from "../../../styles/GlobalStyle";

// Banner

export const BannerWrapper = styled.section`
  
  &.main-hero {
    background: #F5F7FB;

    span {
      color: #EF6957
    }

    .p1 {
      margin-top: 24px
    }

    //.btn {
    //  width: 351px;
    //  margin-top: 56px;
    //}

    .buttons-wrapper {
      display: flex;
      margin-top: 56px;

      .btn {
        margin-top: 0;
        width: 100%;
        height: 64px
      }

      .btn2 {
        flex: 1;
        padding: 0;
        margin-left: 26px;
        height: 64px;
      }

      .vacancy-link {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      a.vacancy-link:hover {
        color: #EF6957
      }
    }
  }

  //.main-hero__wp {
  //  padding-top: 71px;
  //  padding-bottom: 70px; 
  //}

  .main-hero__wp {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 71px;
    padding-bottom: 70px;
    overflow: hidden
  }

  .main-hero__left {
    max-width: 668px;
    margin-right: 20px
  }

  .main-hero__right {
    max-width: 642px;
    line-height: 0;
    margin-right: 20px;
    margin-top: -24px
  }

  .main-hero__right img {
    width: 100%
  }

  @media (min-width: 991px) {
    &.main-hero .p1 {
      line-height:38px
    }

    &.main-hero .btn {
      font-style:normal;
      font-weight: 600;
      font-size: 20px;
      line-height: 24px
    }
  }

  @media (max-width: 1440px) {
    .main-hero__wp {
      padding-top: 63px;
      padding-bottom: 64px; 
    }
    
    .main-hero__right {
      width:85%;
      max-width: 590px
    }
    
    //
    //.main-hero__wp {
    //  padding-top: 130px;
    //  padding-bottom: 130px
    //}

    .main-hero__right {
      margin-right: 0;
      margin-top: 0
    }
    
    &.main-hero .buttons-wrapper {
      margin-top: 30px;
      .btn {
        height: 56px;
        //margin-top: 30px
      }
      
      .btn2 {
        height: 56px;
      }
    }
  }

  @media (max-width: 990px) {
    &.main-hero .buttons-wrapper {
      .btn {
        height:48px
      }
      
      .btn2 {
        height: 48px;
      }
    }
  }

  @media (max-width: 760px) {
    .main-hero__wp {
      -ms-flex-direction:column-reverse;
      flex-direction: column-reverse;
      padding-top: 53px;
      padding-bottom: 58px
    }

    .main-hero__right {
      width: 73%;
      max-width: 500px;
      margin-bottom: 40px;
      margin-left: auto;
      margin-right: auto
    }

    &.main-hero .p1 {
      margin-top: 16px
    }

    &.main-hero .buttons-wrapper {
      .btn {
        //margin-top: 32px
      }
    }
  }

  @media (max-width: 575px) {
    &.main-hero .buttons-wrapper {
      .btn {
        min-width: auto;
        //width: 280px;
        //max-width: 480px
      }
    }
  }
`;

export const BannerContainer = styled.div`
  //padding: 0;
`;

export const BannerText = styled.div`
 
`;

export const BannerImage = styled.div`
  img {
    width: 100%;
  }
`;

// How works

export const HowWorkWrapper = styled.section`

  &.how-work {
    padding-top: 80px;
    padding-bottom: 35px
  }

  &.how-work p {
    margin-top: 12px;
    opacity: .8
  }

  .how-work__text {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-right: 57px
  }

  .how-work__text-item {
    max-width: 630px;
    margin-right: 25px
  }

  .how-work__text-item:last-of-type {
    margin-right: 0
  }

  .how-work__track {
    margin-top: 48px;
    margin-bottom: 25px;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-right: 57px
  }

  .how-work__track,.how-work__track-item {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center
  }

  .how-work__track-item {
    width: 64px;
    min-width: 64px;
    height: 64px;
    border-radius: 50%;
    background: #FFFFFF;
    box-shadow: 0 4px 16px #FFECEA;
    -ms-flex-pack: center;
    justify-content: center;
    line-height: 0
  }

  .how-work__track-item svg {
    line-height: 0
  }

  @media (min-width: 761px) {
    .how-work__track-item:last-of-type {
      display:none
    }

    .how-work__track-item:first-of-type {
      display: -ms-flexbox;
      display: flex
    }
  }

  .how-work__track-bullets {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-pack: justify;
    justify-content: space-between;
    line-height: 0;
    max-width: 614px;
    width: 100%;
    margin-left: 38px;
    margin-right: 38px
  }

  .how-work__track-bullets span {
    line-height: 0;
    width: 5px;
    height: 5px;
    background: #FFD4CE;
    border-radius: 50%
  }

  @media (min-width: 761px) {
    .how-work__track-left,.how-work__track-wp {
      display:-ms-flexbox;
      display: flex;
      -ms-flex-align: center;
      align-items: center
    }

    .how-work__track-left {
      max-width: 755px;
      width: 100%
    }

    .how-work__track-right {
      width: 100%;
      max-width: 630px
    }
  }

  @media (max-width: 1480px) {
    .how-work__text {
      padding-right: 0
    }

  }

  @media (max-width: 1480px) and (min-width:761px) {

    .how-work__track-left {
      max-width:630px;
      margin-right: 25px
    }

    .how-work__track-right {
      max-width: 630px
    }
  }

  @media (max-width: 1440px) and (min-width:761px) {
    .how-work__track {
      padding-right:0
    }

    .how-work__track-left {
      width: 56%;
      margin-right: 0
    }

    .how-work__track-right {
      width: 49%
    }
  }
  
  @media (max-width: 760px) {
    &.how-work {
      padding-top: 32px
    }

    &.how-work .podz {
      font-size: 16px;
      line-height: 22px
    }

    &.how-work p {
      line-height: 20px;
      margin-top: 8px
    }

    .how-work__wp {
      display: -ms-flexbox;
      display: flex;
      margin-top: 24px
    }

    .how-work__track {
      margin-top: 0;
      margin-right: 16px;
      padding-right: 0
    }

    .how-work__track,.how-work__track-bullets {
      -ms-flex-direction: column;
      flex-direction: column
    }

    .how-work__track-bullets {
      -ms-flex-align: center;
      align-items: center;
      height: 150px;
      margin: 14px 0
    }

    .how-work__track-bullets span {
      display: none
    }

    .how-work__track-bullets span:first-of-type,.how-work__track-bullets span:nth-of-type(2),.how-work__track-bullets span:nth-of-type(3),.how-work__track-bullets span:nth-of-type(4),.how-work__track-bullets span:nth-of-type(5),.how-work__track-bullets span:nth-of-type(6) {
      display: block
    }

    .how-work__track-item {
      min-width: 44px;
      width: 44px;
      height: 44px
    }

    .how-work__track-item svg {
      width: 18px;
      height: 17px
    }

    .how-work__track-right {
      display: none
    }

    .how-work__text {
      display: flex;
      flex-direction: column;
    }

    .how-work__text-item {
      margin-right: 0
    }

    .how-work__text-item:nth-of-type(2) {
      margin-top: 28px
    }
  }
`;

export const HowWorkContainer = styled.div`
  h2 {
    font-style: normal;
    font-weight: 600;
    font-size: 36px;
    line-height: 44px;
  }
`;

export const HowWorkTrack = styled.div`
`;

export const HowWorkTrackLeft = styled.div`
`;

export const HowWorkTrackBullets = styled.div`
`;

export const HowWorkTrackRight = styled.div`
`;

export const HowWorkTrackItem = styled.div`
`;

export const HowWorkText = styled.div`
`;

export const HowWorkTextContainer = styled.div`
`;

// Main sub

export const MainSubWrapper = styled.section`
  &.main-sub {
    margin-top: 118px;
    position: relative;
    background: #F5F7FB
  }

  .main-sub__img {
    width: 500px;
    position: relative
  }

  @media (min-width: 1900px) {
    .main-sub__img:before {
      content:"";
      position: absolute;
      left: -39vw;
      top: 0;
      height: 100%;
      width: 50vw;
      background: #EF6957
    }
  }

  .main-sub__img img {
    position: absolute;
    height: 356px;
    bottom: -1px
  }

  .main-sub__img img:first-child {
    left: -240px
  }

  .main-sub__img img:nth-child(2) {
    width: 396px;
    height: 477px;
    right: 19px
  }

  .main-sub__wp {
    display: -ms-flexbox;
    display: flex
  }

  .main-sub__info {
    padding-top: 60px;
    padding-bottom: 53px;
    max-width: 841px;
    width: 60%;
    margin-left: auto
  }

  .main-sub__info-bottom {
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: justify;
    justify-content: space-between;
    padding-top: 40px
  }

  .main-sub__info-bottom .p2 {
    margin-top: 8px
  }

  .main-sub__info-bottom .btn {
    width: 305px
  }

  .main-sub__text .p2 {
    border-bottom: 1px solid rgba(138,138,137,.4);
    padding-bottom: 39px
  }


  @media (max-width: 1440px) {
    .main-sub__info {
      padding-top: 39px;
      padding-bottom: 39px
    }

    .main-sub__info-bottom {
      display: block;
      padding-top: 25px
    }

    .main-sub__info-bottom .btn {
      margin-top: 24px
    }

    .main-sub__text .p2 {
      padding-bottom: 23px
    }

    .main-sub__img img:first-of-type {
      height: 100%;
      left: -340px
    }

    .main-sub__img img:nth-of-type(2) {
      right: 55px
    }
  }

  @media (max-width: 990px) {
    .main-sub__img img:first-of-type {
      left: -395px
    }

    .main-sub__info-bottom .btn {
      width: 280px
    }
  }

  @media (max-width: 760px) {
    &.main-sub {
      margin-top: 52px
    }

    .main-sub__wp {
      display: block
    }

    .main-sub__img {
      height: 199px
    }

    .main-sub__img img:first-of-type {
      height: 199px;
      left: -75px
    }

    .main-sub__img img:nth-child(2) {
      width: 225px;
      height: 271px;
      right: 170px
    }

    .main-sub__info {
      width: 100%
    }
  }

  @media (max-width: 575px) {
    .main-sub__img img:first-of-type {
      left: -156px
    }

    .main-sub__img img:nth-of-type(2) {
      right: 248px
    }

    .main-sub__info {
      padding-top: 21px;
      padding-bottom: 36px
    }

    .main-sub__info-bottom {
      padding-top: 24px
    }

    .main-sub__info-bottom h3 {
      font-size: 22px;
      line-height: 27px
    }

    .main-sub__info-bottom .p2 {
      font-size: 14px;
      line-height: 20px
    }
  }
`;

export const MainSubContainer = styled.div`
`;

export const MainSubImage = styled.div`
  max-width: 100%;
`;

export const MainSubInfo = styled.div`
`;

export const MainSubInfoBottom = styled.div`
`;

export const MainSubInfoBottomText = styled.div`
`;

// Differ

export const DifferWrapper = styled.section`
  &.main-differ {
    background: #F5F7FB;
    padding: 97px 0 94px;

    h2 span {
      color: #EF6957
    }
  }

  .main-differ__list {
    display: -ms-flexbox;
    display: flex;
    margin-top: 48px
  }

  .main-differ__item {
    max-width: 323px;
    margin-right: 6.7%;
    width: 100%
  }

  .main-differ__item:last-of-type {
    margin-right: 0
  }

  .main-differ__item-img {
    width: 64px;
    height: 64px;
    background: #FFFFFF;
    box-shadow: 0 4px 16px #EEF2F9;
    border-radius: 50%;
    line-height: 0;
    margin-bottom: 26px;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-align: center;
    align-items: center;
    -ms-flex-pack: center;
    justify-content: center
  }

  .main-differ__item p {
    opacity: .8
  }

  .main-differ__item p br {
    display: none
  }

  @media (max-width: 1440px) {
    .main-differ__list {
      -ms-flex-pack: justify;
      justify-content: space-between
    }

    .main-differ__item {
      margin-right: 10px;
      margin-left: 10px
    }

    .main-differ__item br {
      display: none
    }
  }

  @media (max-width: 990px) {
    .main-differ__list {
      -ms-flex-wrap: wrap;
      flex-wrap: wrap
    }

    .main-differ__item {
      width: 47%;
      margin: 0 0 20px
    }

    .main-differ__item-img {
      width: 44px;
      height: 44px;
      margin-bottom: 8px
    }

    .main-differ__item-img img {
      width: 24px;
      height: 24px;
    }


    .main-differ__item-img svg {
      width: 22px;
      height: 25px
    }
  }

  @media (max-width: 575px) {
    &.main-differ {
      padding: 28px 0 5px
    }

    .main-differ__list {
      margin-top: 24px
    }

    .main-differ__item {
      width: 100%;
      margin-left: 0;
      margin-right: 0
    }

    .main-differ__item-img {
      margin-bottom: 9px
    }

    .main-differ__item p {
      font-size: 12px;
      line-height: 22px
    }
  }
`;

export const DifferInfo = styled.div`
`;

export const DifferInfoItem = styled.div`
`;

export const DifferInfoImage = styled.div`
`;

export const DifferInfoText = styled.p`
`;

// Look

export const LookWrapper = styled.section`
  &.main-look {
    padding-top: 100px;
    padding-bottom: 100px
  }

  .main-look__wp {
    display: -ms-flexbox;
    display: flex
  }

  .main-look__wp h2 {
    //padding-top: 29px;
    position: relative;
    min-width: 370px;
    margin-right: 15px
  }

  .main-look__wp h2:before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 5px;
    background: #EF6957;
    width: 120px
  }

  .main-look__right {
    max-width: 1022px;
    width: 90%;
    margin-left: auto;
    padding-top: 14px
  }

  .main-look__right p {
    margin-bottom: 8px
  }

  .main-look__right p:last-of-type {
    margin-bottom: 0
  }

  @media (max-width: 1440px) {

    .main-look__wp h2 {
      min-width: 330px
    }
  }

  @media (max-width: 990px) {

    &.main-look {
      padding: 60px 0
    }

    .main-look__wp h2 {
      min-width: 210px
    }
  }

  @media (max-width: 575px) {
    &.main-look {
      padding-top: 48px;
      padding-bottom: 47px
    }
    
    .main-look__wp {
      display: block
    }

    .main-look__right {
      width: 100%;
      margin-top: 24px
    }

    .main-look__wp h2 {
      padding-top: 21px
    }

    .main-look__wp h2:before {
      width: 133px
    }

    .main-look__right {
      margin-top: 11px
    }

    .main-look__right p {
      line-height: 22px;
      margin-bottom: 4px
    }
  }
`;

export const LookContainer = styled.div`
  p {
    margin-bottom: 8px;
  }
  
  h2.main-look__title {
    font-size: 18px;
    line-height: 22px;
    padding-top: 21px;
  }
  
  @media (min-width: 575px) {
    .main-look__right p:first-child {
      margin-bottom: 0;
    }
  }

  @media (max-width: 990px) {
    h2.main-look__title{
      font-size: 14px;
      line-height: 20px;
    }
  }
`;
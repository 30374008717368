import React from "react";
import Banner from "./Banner";
import HowWork from "./HowWork";
import MainSub from "./MainSub";
import Vacancies from "./Vacancies";
import Differ from "./Differ";
import Look from "./Look";
import Mailling from "../../Utils/Mailing/Mailling";
import MessageModal from "../../Layout/MessageModal";
import {SUCCESS} from "../../../helpers/common";
import {useRouter} from "next/router";


const HomePage = ({ vacancies = [], profile, bannerText }) => {
  const route = useRouter();

  return (
    <>
      <MessageModal
        title={'Оплата прошла успешно'}
        type={SUCCESS}
        visible={!!route.query?.Shp_OrderType}
        setVisible={() => {
          route.push({
            pathname: '/vacancies-subscription',
            query: {}
          })
          //setMessageForPaid(false)
        }}/>

      <Banner bannerText={bannerText} />
      <HowWork />
      {/*<MainSub />*/}
      {
        vacancies?.length > 0 && (
          <Vacancies vacancies={vacancies} profile={profile} />
        )
      }
      <Differ />
      <Look />
      <Mailling />
    </>
  )
}

export default HomePage;